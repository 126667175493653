

@media screen and (max-width: 767px) {

/* !!------------------------------------ */
/* !!------------------------------------ */
/* !! SP */
/* !!------------------------------------ */
/* !!------------------------------------ */


$px : 0.267vw;

.sp {
  display: block !important;
}

.pc {
  display: none !important;
}


/* !!------------------------------------ */
/* !! 共通css */

html{
	font-size: 62.5%;
	margin: 0;
	&.font-large{
		font-size: 80%;
	}
}

body{
	margin: 0;
	//overflow-x: hidden;
	@include gothic;
	font-feature-settings: "palt";
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: 100%;
	//max-width: 1920px;
	background: #fff;
	&:not(.top){
		padding-top: 60px;
	}
}

p{
	font-size: 1.4rem;
	line-height: 1.66;
}

main{
	display: block;
	min-width: 320px;
}

main > section {
	
	.inner{
		max-width: 1266px;
		margin: 0 auto;
		position: relative;
	}

}

ul.disc{
	list-style: disc;
	padding-left: 1.6em;
	li{
		font-size: 1.6rem;
	}
}

//!! - 見出し
h1.sec,
h2.sec{
	text-align: center;
	font-weight: normal;
	font-size: 1.2rem;
	line-height: 1;
	i{
		@include BLW;
		font-weight: 300;
		line-height: .8;
		font-size: 2.2rem;
		display: block;
		margin-bottom: 10px;
	}
}

h3.hd{
	font-size: 1.2rem;
	line-height: 1;
	font-weight: 300;
	i{
		font-size: 2rem;
		display: block;
		font-weight: bold;
		margin-bottom: 10px;
		letter-spacing: .08em;
	}
}

//!! - btn
.btn-more{
	border: 1px solid #A39994;
	width: 150px;
	text-align: center;
	line-height: 28px;
	display: inline-block;
	font-size: 1.2rem;
	position: relative;
	&:after{
		content: "";
		width: 40px;height: 5px;
		position: absolute;
		top: 50%;
		left: -20px;
		@include transform(translateY(-50%));
		@include bg-img(url(../img/arr-R-s@3x.png)); //$url,$size,$position,$repeat
	}
	&.w{
		border: 1px solid #fff;
		color: #FFF;
		&:after{
			@include bg-img(url(../img/arr-R-W-s@3x.png));
		}
	}
}

.btn-s{
	width: auto;
	line-height: 36px;
	text-align: center;
	border: 1px solid $txt;
	display: block;
	font-size: 1.2rem;
	position: relative;
	cursor: pointer;
	&:after{
		content: "";
		@include anchor($txt);//$color,$size,$Bw,$left,$right;					
	}
	&.down{
		&:after{
			content: "";
			border-width: 0 0 1px 1px !important;	
			margin-top: -1px;
			@include transform(rotate(-45deg) translateY(-75%));			
		}
	}
}

/* !!------------------------------------ */
/* !! ナビゲーション */
#menu-btn{
	width: 60px;
	height: 20px;
	position: absolute;
	top: 20px;
	right: 0;
	z-index: 1;
	cursor: pointer;
	pointer-events: auto;
	transition: 200ms;
	i{
		display: block;
		height: 1px;
		width: 100%;
		background: $txt;
		position: absolute;
		transform-origin: right center;
		transition:500ms $ease1;
		&._1{
			top: 0;
			right: 0;
		}
		&._2{
			top: 9px;
			right: -10px;
		}
		&._3{
			top: 18px;
			right: -20px;
		}
		body.top & {
			background: #fff;
		}
		body.moved &{
			background: $txt;
		}
		body.menu_on &{
			background: $txt;
		}
	}
	body.menu_on & {
		right: 20px;
		._1{
			right: 0;
			top: 9px;
			transform: rotate(-45deg) scaleX(.2);
		}
		._2{
			right: 0;
			transform:  scaleX(.5);
		}
		._3{
			right: 0;
			top: 9px;
			transform: rotate(45deg) scaleX(.2);
		}
	}
}

header.gnav{
		z-index: 2000;

	//position: fixed;
	//top: 0;left: 0;
	//z-index: 10;
	//background: #fff;
	height: 100vh;
	background: none;
	pointer-events: none;
	body.menu_on &{
		background: #fff !important;
		pointer-events: auto;
		#logo{
			background-image: url(../img/logo@3x.png) !important;
		}
	}
	&:before{
		opacity: 1;
		transition: 300ms;
		content:"";
		height: 60px;
		width: 100%;
		display: block;	
		position: absolute;
		top: 0;left: 0;
		z-index: 0;
		background: rgba(255, 255, 255,1);
		
	}
	#logo{
		width: 120px;
		pointer-events: auto;
		display: block;
		height: 27px;
		position: absolute;
		top: 20px;
		left: 20px;
		@include bg-img(url(../img/logo@3x.png),100% auto); 
		img{
			display: none;
		}
	}
	body.top & {
		@include transform(translateY(0));
		transition: 300ms ease-in-out;
		background: none;
		&:before{
			opacity: 0;
		}
		#logo{
			@include bg-img(url(../img/logo-w@3x.png),100% auto); 
		}
	}
	
	body.moved &{
		@include transform(translateY(0));
		&:before{
			opacity: 1;
			box-shadow: 0 0 2px rgba(0, 0, 0, 0.19);
		}
		#logo{
			@include bg-img(url(../img/logo@3x.png),100% auto); 
		}
	}

	.inner{
		padding: 0;
		padding-top: 60px;
		height: 100%;
		perspective: 1000px;
	}
	.wrap{
		position: relative;
		opacity:0;
		pointer-events: none;
		top: 0px;
		left: 0;
		width: 100%;
		//background: #fff;
		height: 100%;
		transition: 800ms $ease1;
		@include transform(translateZ(-50px));
		body.menu_on & {
			opacity: 1;
			pointer-events: auto;
			@include transform(translateZ(0px));
		}
		&:after{
			content: "";
			position: absolute;
			width: 100%;
			height: 150%;
			z-index: 0;
			background: #fff;					
		}
	}
	nav{
		position: absolute;
		//display: none;
		top: 50%;
		left: 50%;
		margin-top: -35px;
		@include transform(translate(-50%,-50%));
		white-space: nowrap;
		z-index: 1;
		ul{
			font-size: 0;
			li{
				font-size: 15px;
				display: block;
				position: relative;
				//@include tap(1.1);
				cursor: pointer;
				a{
					display: block;
					line-height: 30px;
					padding: 10px 0;
					position: relative;
					-webkit-tap-highlight-color:rgba(0,0,0,0);
				
					&:after{
						content: "";
						height: 2px;
						width: 100%;
						background: $blue;	
						display: block;
						@include transform(scaleX(0));
						transition: 0 !important ;
						opacity: 0;	
					}
					&.current,&:active{
						&:after{
							opacity: 1;
							@include transform(scaleX(1));											
						}
					}
				}
			}
		}
	}
	.fontsize{
		position: absolute;
		z-index: 1;
		right: 20px;top:auto;
		bottom: 20px;
		margin-top: 0;
		@include transform(translateY(0));
	}
	
}//header.gnav

/* !! - - -  */
/* !! gnavTop */

body.top aside.gnav{
	display: none;

}//#gnav


.fontsize{
	line-height: 25px;
	font-size: 14px;
	margin-top: 30px;
	.toggle{
		display: inline-block;
		border: 2px solid $txt;
		margin-left: 10px;
		position: relative;
		
		&:after{
			content: "";
			width: 50%;
			display: block;
			height: 100%;
			position: absolute;
			top: 0;left: 0;
			z-index: 0;
			background: $txt;
			transition: 100ms $ease1;
			@include transform(translateX(0));
		}
		
		html.font-large &{
			&:after{
				@include transform(translateX(100%));
			}
			.rg{
				color: $txt;
			}
			.lg{
				color: #fff;
			}
		}
		
		>div{
			display: inline-block;
			position: relative;
			z-index: 1;
			width: 50px;
			text-align: center;
			line-height: 25px;
			cursor: pointer;
			font-weight: 600;
			&.rg{
				color: #fff;
			}
		}
	}
	
}//#fontsize


/* !!------------------------------------ */
/* !! トップページ */

#mv{
	padding-left: 0;
	background: #eee;
	position: relative;
	width: 100%;
	height: 100vh;
	overflow: hidden;
	.h1-wrap{
		background: #000;
		position: relative;
		height: 100%;
		.bg{
			position: relative;
			opacity: 0;
			width:100%;
			height:100%;
			z-index: 0;
			transition: 1500ms opacity;
			.inner{
				transform-origin: center center;
				transform: scale(1.1);
				width:100%;
				transition: 3000ms;
				height:100%;
				display: block;
				@include bg-img(url(../../img/mv1@2x.jpg),cover,center center); //$url,$size,$position,$repeat
			}
			&.on{
				opacity: 1;
				.inner{
					transform: scale(1.1);
				}
			}
			&.aniamting{
				will-change:transform;
			}
		}
		#h1txt{
			position: absolute;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			justify-content: center;
			top: 50px;
			//background: #eee;
			@include transform(translateY(0));
			left:0*$px;
			padding: 0 30*$px;
			height: calc(100% - 210px);
			img{
				width: 100% !important;
				height: auto !important;
			}
			width: auto;
			z-index: 1;
			span {
				width: 100%;
				padding: 15*$px 0;
				font-size: 14*$px;
				line-height: 1.64;
				@include pfix(.64em);
				color: #ffffff;
				font-weight: 400;
				display: block;
				position: relative;
				left: 0;
				top: 0;
				white-space: normal;
				@include transform(translateY(0));
				text-shadow: 0 0 4px #000;
			}
		}
	}
	
}//#mv



#information-wrap{
	
	position: absolute;
	bottom: 0;
	background: #EEEFEF;
	left: 0;
	width: 100%;
	
	.toIndex{
		width: 50px;
		height: 100%;
		background: $txt;
		position: absolute;
		top: 0;right: 0;
		display: block;
		&:hover{
			img{
				@include transform(translate(-50%,-50%) scale(1.2));
			}
		}
		img{
			position: absolute;
			top: 50%;
			left: 50%;
			transition: 100ms $ease1;
			@include transform(translate(-50%,-50%) scale(1));
		}
	}
	
}//#information-wrap

.hdBox{
	position: relative;
	height: 40px;
	width: 150px;
	border: 1px solid $txt;
	i{
		@include centering;
		@include BBS;
		white-space: nowrap;
		font-weight: 500;
		font-size: 1.4rem;
		letter-spacing: .05em;
		line-height: 1;
	}
}


/* !! - - -  */
/* !! seminar-and-event */
#seminar-and-event{
	height: 80px;
//	position: relative;
	padding-left: 80px;
	.hdBox{
		left: 10px;
		//top: 50%;
		width: 60px;
		height: 60px;
		border-width: 1px 0;
		//position: absolute;
		//@include transform(translateY(-50%));
		text-align: center;
	}
	
	ul.list{
		margin: 0;
		padding: 10px 0;
		font-size: 0;
		white-space: nowrap;
		margin-right: 60px;
		li{
			display: inline-block;
			font-size: 1.4rem;
			width: 100%;
			margin-right: 0px;
			position: relative;
			padding-left: 70px;
			&:nth-of-type(n+2){
				display: none;
			}
			.img{
				width: 60px;
				height: 60px;
				position: absolute;
				top: 0;left: 0;
				background: #ccc;
				overflow: hidden;
				img{
					position: relative;
					left: 50%;
					@include transform(translateX(-50%));
				}
			}
			.cat{
				background: $blue;
				color: #fff;
				font-size: 1rem;
				width: 50px;
				line-height: 20px;
				@include BBS;
				text-align: center;
				letter-spacing: .08em;
				
			}
			.ttl{
				white-space: normal;
				line-height: 1.43;
				@include pfix(.43em);
				padding-top: 5px;
				height: 2.9em;
				overflow: hidden;
				text-align: justify;
			}
			
		}
	}
	
	
}//#seminar-and-event

/* !! - - -  */
/* !! blog-and-news */
#blog-and-news{
	position: relative;

	height: 80px;
	background: $base;
	position: relative; 
	padding-left:80px;
	.hdBox{
		position: absolute;
		left: 10px;
		top: 10px;
		height: 60px;
		width: 60px;
		border-width: 1px 0;
		border-color: #fff;
		text-align: center;
		color: #fff;
	}
	
	.wrap {
		margin: 0;
		width: calc(100% - 50px) !important;
	}
	
	.swiper-wrapper{
		&:focus{outline: none;}
		height: 80px;
		width: 100%;
		overflow: hidden;
		white-space: nowrap;
		overflow: hidden;
		position: relative;
		.swiper-slide{
			width: calc(100% - 45px ) !important;
			height: 80px;
			padding-left: 70px !important;
			p{
				position: relative;
				top: 50%;
				color: #fff;
				//font-size: 1.2rem;
				overflow: hidden;
				text-overflow: ellipsis;
				margin-right: 50px;
				@include transform(translateY(-50%));
			}
			.ttl{
				white-space: normal;
				line-height: 1.43;
				@include pfix(.43em);
				padding-top: 5px;
				height: 2.9em;
				overflow: hidden;
				text-align: justify;
				color: #FFF;
			}
			.img{
				width: 60px !important;
				overflow: hidden;
				a{
					display: block;
					height: 100%;
					position: relative;
					z-index: 1;
				}
				img{
					position: absolute;
					top: 50%;
					left: 50%;

					@include transform(translate(-50%,-50%)!important);
				}
			}
		}
	}
	
	.slick-arrow{
		font-size: 0;
		background-image: none;
		width: 40px;
		height: 40px !important;
		border-right: 1px solid #707070;
		margin: 0 !important;
		top: auto;bottom: 0;
		position: relative;
		background: #595757;
		height: 80px;
		position: absolute;
		right: 0;
		z-index: 2;
		transform: rotate(90deg);
		cursor: pointer;
		@include bg-img(url(../../img/anchor-right@2x.png),10px 16px); 
		&.slick-prev{
			right: 0px;
			transform: rotate(90deg);
			top: 0px;
			@include bg-img(url(../../img/anchor-left@2x.png),10px 16px); 
		}
		& + div img{
			@include transform(translate(-50%,-50%) rotate(-180deg));
		}
		
	}
	
	
}//#blog-and-news




/* !! - - -  */
/* !! SRB TECH CONSULTING */

section#about-srbtech{
	background: #fff;
	@include bg-img(url(../../img/grade2_sp@3x.png),100% auto,right 50px); //$url,$size,$position,$repeat
	padding: 50*$px 0;
	padding-bottom: 0;
	position: relative;
	.img1{
		position: relative;
		z-index: 2;
		margin: 0 30px;
		width: 245*$px;
		height: 300*$px;
		overflow: hidden;
		img{
			width: auto !important;
			height: 100% !important;
			position: absolute;
			right: 0;
			top: 0;
		}
	}
	.img2{
		position: absolute;
		z-index: 1;
		left: auto;right: 30*$px;
		top: 35*$px;
		width: 459px;
		img{
			width: 100% !important;
			height: auto !important; 
		}
	}
	h2{
		font-size: 1.2rem;
		font-weight: normal;
		margin: 0 30*$px;
		margin-bottom: 30*$px;
		strong{
			font-size: 2.2rem;
			display: block;
			line-height: 1;
			@include BLW;
			font-weight: 300;
			margin-bottom: 5px;
		}
	}
	.txt{
		padding: 30px;
		margin-left: 90*$px;
		margin-top: -150px;
		position: relative;
		bottom: 0px;right: 0;
		z-index: 3;
		background: #fff;
		p{
			font-size: 1.4rem;
			line-height: 2.25;
			padding-top: 0;
			@include pfix(1em);
		}
		.btn-more{
			margin-top: 30px;
		}
	}
	
	
}//#about-srbtech



/* !! - - -  */
/* !! MAIN FEATURES */
#main-features{
	position: relative;
	@include bg-img(url(../../img/img0_sp@3x.jpg),100% auto,center bottom); //$url,$size,$position,$repeat
	padding-top: 50*$px;
	padding-bottom: 50*$px;
	h2{
		text-align: justify;
		&:after{
			content: "";
			display: block;
			width: 50px;
			height: 1px;
			background: $txt;	
			margin-top: 30px;			
		}
	}
	
	h3{
		text-align: center;
	}
	
	&:before{
		content: "";
		display: block;
		position: absolute;
		top: 0;left: 0;
		@include grade1;
		width: 100%;
		height: 100%;
		z-index: 0;
	}
	>.inner{
		//background: #ddd;
		//max-width: 1166px;
		padding: 0 30*$px;
		//position: relative;
		//z-index: 1;
	}
	.btn-more{
		background: #fff;
	}
	.row{
		//max-width: 966px;
		//position: relative;
		//margin: 0 auto;
		margin-top: 30*$px;
		//border-bottom: 1px solid $txt;
		padding-bottom: 50*$px;
		&:last-of-type{border-bottom: 0;padding-bottom: 30*$px;}
		p{
			font-size: 1.6rem;
			line-height: 2;
			@include pfix(1em);
			margin-top: 30*$px;
		}
		&._1{
			//padding-right: 50%;
			p{
				max-width: 100%;
			}
		}
		
		.stat{
			position: relative;
			top: 0;
			right: 0;
			margin-top: 50*$px;
			width: 100%;
			h4{
				text-align: center;
				strong{
					display:block;
					padding: 5px 10px;
					i{
						display: inline-block;
					}
					@include side-kakko(10px,1px);
					line-height: 1.5;
					font-size: 1.6rem;
					font-weight: bold;
				}
			}
			.rate{
				margin-top: 25px;
				width: 100%;
				height: 156*$px;
				@include bg-img(url(../../img/stat-bg@3x.png)); //$url,$size,$position,$repeat
				>div{
					position: relative;
					width:134*$px;
					height: 156*$px;
					.inner{
						@include centering;
						color: #fff;
						.num{
							font-size: 52px;
							line-height: .8;
							@include BBS;
							text-align: center;
							i{
								font-size: 28px;
							}
							&:after{
								content: "";
								width: 30px;
								height: 1px;
								display: block;
								background: #fff;
								margin: 10px auto;
							}
						}
						.txt{
							line-height: 1;
							font-size: 1.4rem;
							text-align: center;
							white-space: nowrap;
						}
					}
				}
			}
		}//.stat
	}
	
	
}//#main-features



/* !! - - -  */
/* !! SERVICE */
section#service{
	
	padding: 50*$px 0;
	padding-bottom: 0;
	.wrap{
		margin-top: 30*$px;
		display: block !important;
		>div{
			width: 100%;
			position: relative;
			padding: 0 30*$px;
			padding-top: 120*$px;
			padding-bottom: 50*$px;

			.img{
				height: 220*$px;
				width: 100%;
				background: #eee;
				position: absolute;
				top: 0;
				right: 0;
			}
			.inner{
				width: 100%;
				padding:30*$px;
				height: 100%;
				position: relative;
				background: #fff;
				h3{
					margin-bottom: 30*$px;
				}
				p{
					font-size: 16px;
					line-height: 2;
				}
			}
			p.ar{
				text-align: right;
				padding: 0 30*$px;
			}
			.btn-more{
				position:relative;
				bottom: 0;
				right:0 !important;
				margin-right: 0;
			}

			&._1{
				.inner{
					margin-left: 0;
				}
				.btn-more{
					right: 100px;
				}
				.img{@include bg-img(url(../../img/img2@2x.jpg),cover); }
			}
			&._2{
				.inner{
					margin-right: 0;
				}
				.img{
					@include bg-img(url(../../img/img3@2x.jpg),cover); 
					left: 0;right: auto;
				}
			}
			&._3{
				width: 100%;
				.img{
					width: 100vw;
					left: 50%;
					right: auto;
					@include bg-img(url(../../img/img4@2x.jpg),cover); 
					@include transform(translate(-50%,0));
				}
				.inner{
					width: 100%;
				}
			}
		}
	}
	
}//#service



/* !! - - -  */
/* !! CONSULTING CASE */

#consukting-case{
	padding: 30*$px;
	padding-bottom: 0;
	position: relative;
	&:before{
		//position: absolute;
		//top: 0;left: 0;
		//content: "";
		width: 100%;
		height: 200*$px;
		z-index: 0;
		@include bg-img(url(../../img/img5_sp@3x.jpg),cover); //$url,$size,$position,$repeat
	}
	
	.case-wrap{
		margin: 0 auto;
		margin-top: 30*$px;
		//max-width: 1100px;
		background: rgba(255, 255, 255, 0.9);
		padding: 30*$px;
		>.case{
			width: 100%;
			//position: relative;
			padding-bottom: 30*$px;
			&:last-of-type{
				padding-bottom: 0;
			}
			.txt{
				position: relative;
			}
			.img{
				height: auto;
				background: #eee;
				margin-bottom: 20*$px;
				img{
					width: 100% !important;
					height: auto !important;
				}
			}
			.ttl{
				font-size: 1.8rem;
				//line-height: 1.5;
				//@include pfix(.5em);
				//padding-bottom: 10px;
				//margin-bottom: 10px;
				//border-bottom: $line;
			}
			.desc{
				font-size: 1.4rem;
				
			}
			p.ar{
				margin-top: 20*$px;
			}
			.btn-more{
				position: relative;
				bottom: 0px;
				right: 0;
			}
			

			
		}//>.case
		
	}//.case-wrap
	
}//#consukting-case



/* !! - - -  */
/* !! PERFORMANCE COMPANY */

#performance-company{
	padding: 50*$px 0;
	padding-bottom: 0;
	>.inner{
		max-width: 1140px;
	}
	
	.logo-list{
		border: $line;
		border-width: 0 1px;
		position: relative;
		margin:30*$px;
		margin-bottom: 20*$px;
		&:before{
			content: "";
			display: block;
			height: 1px;
			width: 100vw;
			border-bottom: $line;
			position: absolute;
			top: 80px;
			left: 50%;
			@include transform(translate(-50%,0));
		}
		&:after{
			content: "";
			display: block;
			height: 1px;
			width: 100vw;
			border-bottom: $line;
			position: absolute;
			bottom: 80px;
			left: 50%;
			@include transform(translate(-50%,0));
		}
		li{
			width: calc(100%/2);
			height: 80px;
			position: relative;
			border-right: $line;
			&:nth-of-type(3n){
				border-right: $line;
			}
			&:nth-of-type(2n){
				border-right: 0;
			}
			img{
				@include centering;
				transform-origin: center center;
				@include transform(translate(-50%,-50%) scale(.6));
			}
		}
	}
	
	.desc{
		text-align: center;
		font-size: 1.4rem;
	}
	
}//#performance-company




/* !! - - -  */
/* !! COMPANY PROFILE */

#company-profile{
	padding: 50px 0;
	padding-bottom: 0;
	.inner{
		max-width: 100%;
		margin: 0;
	}
	.txt-wrap{
		//width: 100%;
		margin-top: 30*$px;
		position: relative;
		padding-left: 0;
		padding: 30*$px;
		@include bg-img(url(../../img/img6@2x.jpg),cover); //$url,$size,$position,$repeat
		.txt{
			padding: 30*$px;
			max-width: 583px;
			position: relative;
			&:before{
				content: "";
				position: absolute;
				top: 0;left: 0;
				z-index: 0;
				width:100%;
				height:100%;
				opacity: .8;
				@include bg-img(url(../../img/grade1@3x.png)); //$url,$size,$position,$repeat
			}
			p{
				color: #fff;
				position: relative;
				z-index: 1;
				font-size: 1.6rem;
				line-height: 2;
				@include pfix(1em);
			}
		}
		p.ar{
			margin-top: 30px !important;
			margin-bottom: 0 !important;
		}
		.btn-more{
			margin: 0 !important;
		}
	}
	
}//#company-profile


/* !! - - -  */
/* !! page-footer */

#page-footer{
	padding: 50*$px 30*$px;
	.wrap{
		max-width: 1050px;
		margin: 0 auto;
		>div{
			width: 100%;
			height: auto;
		}
	}
	
	#FB-wrap{
		order: 2;
		height: 500px;
		iframe{
			display: block;
			width:100%;
			height:500px;
		}
	}
	
	#post-list-wrap{
		order: 1;
		.h2-wrap{
			height: auto;
			padding: 30*$px 0;
			border-top: 1px solid $txt;
			border-bottom: 1px dotted  $txt;
			h2{
				position: relative;
				top: 0;
				@include transform(translateY(0));
			}
		}
		
		ul.post-list{
			height: 220*$px;overflow: auto;
			border-bottom: 1px solid $txt;
			margin-bottom: 50*$px;
			li{
				position: relative;
				min-height: 0;
				padding: 15px 0;
				padding-left: 0;
				padding-right: 0;
				border-bottom: $line;
				&:last-of-type{
					border-bottom: 0;
				}
				&:after{
					@include anchor($size:6px,$right:0px,$Bw:1px);
				}
				>a{
					@include a-cover;
				}
				.img{
					display: none;
					//background: #efefef;
					//width: 103px;
					//height: 68px;
					//position: absolute;
					//top: 50%;left: 0;
					//@include transform(translateY(-50%));
				}
				
				.txt{
					font-size: 0;
					.cat{
						background: $blue;
						color: #fff;
						font-size: 1.4rem;
						width: 100px;
						line-height: 20px;
						@include BBS;
						text-align: center;
						letter-spacing: .08em;
						display: inline-block;
					}
					.date{
						display: inline-block;
						line-height: 20px;
						margin-left: 15px;
						font-size: 1.2rem;
					}
					.ttl{
						white-space: normal;
						line-height: 1.43;
						@include pfix(.43em);
						padding-top: 5px;
						font-size: 1.4rem;
						position: relative;
						padding-right: 20px;
						&:after{
							display: none;
							//@include anchor($size:6px,$right:0px,$Bw:1px);
						}
					}

				}

			}
		}
	}
	
}//#page-footer



/* !!------------------------------------ */
/* !! お問い合わせバナー */

.bnr-contact.sp{
	background: $base;
	line-height: 50px;
	color: #fff;
	display: block;
	font-weight: bold;
	text-align: center;
	font-size: 1.4rem;
	letter-spacing: .1em;
	border-radius: 4px;
	@include bg-img(url(../img/bnr-bg@3x.png),100% auto,center center); //$url,$size,$position,$repeat
	i{
		position: relative;
		padding-left: 32px;
		&:before{
			content:"";
			
			width: 24px;
			height: 24px;
			@include bg-img(url(../img/icon-mail@3x.png),100% auto);
			position: absolute;
			left: 0;top: 50%;
			@include transform(translateY(-50%));
		}
	}
	
	&.fixed{
		position: fixed;
		bottom: 0;left: 0;
	}
}

.bnr-contact-wrap{
	background: #efefef;
	padding: 30px 30px;
	&.fixed{
		position: fixed;
		bottom: 0;
		background: none;
		padding: 0;
		left: 0;
		z-index: 2000;
		width: 100%;
		overflow: hidden;
		transition: 300ms;
		text-align: center;
		pointer-events: auto;
		box-shadow: 0 0 2px rgba(0, 0, 0, 0.19);
		@include transform(translateY(0px));
		a{
			line-height: 60px;
			display: block !important;
			padding: 0 15px;
			width: auto !important;
			border-radius: 0;
		}
		body.top &{
			opacity: 0;
			pointer-events: none;
			@include transform(translateY(30px));
		}
		body.top.fv_passed &{
			opacity: 1;
			pointer-events: auto;
			@include transform(translateY(0px));
		}
		body.foot_inview &{
			opacity: 0 !important;
			pointer-events: none;
			@include transform(translateY(30px));
		}
	}
	
	body.contact & {
		display: none !important;
	}

}


/* !!------------------------------------ */
/* !! フッター */

.btn-toTop{
	width: 50*$px;
	height: 50*$px;
	border-radius: 50%;
	background: $base;
	position: relative;
	display: block;
	img{
		@include centering;
		width: 20*$px;
		height: auto;
	}
}

#footer{
	//border-top: $line;
	
	.inner{
		margin: 30px !important;
		//max-width: 1266px;
		position: relative;

		padding: 0;
		.btn-toTop{
			position: absolute;
			right: -15*$px;top: -30*$px;
			@include transform(translateY(-50%));
		}
		
	}
	
	#f-logo{
		position: relative;
		top: 0;left: 0;
		width: 120px;
		display: block;
		img{
			width: 100% !important;
			height: auto !important;
		}
	}
	
	.f-nav{
		ul{
			white-space: nowrap;
			font-size: 0;
			margin-top: 30px;
			li{
				display: block;
				font-size: 1.2rem;
				margin-top: 20px;
				&:after{
					display: none;
					//content: "|";
					//padding: 0 10px;												
				}
				&:last-of-type{
					&:after{
						content: none;
					}
				}
			}
		}
	}
	
	.sns{
		padding-left:0;
		position: relative;
		right: 0;
		top: auto;right: 0;
		text-align: right;
		bottom: 0;
		margin-top: 30px;
		white-space: nowrap;
		@include transform(translateY(0));
		i{
			display: inline-block;
			position: relative;
			line-height: 34px;
			left: 0;top: 0;
			@include transform(translateY(0));
			@include BBS;
			font-size: 1.4rem;
			letter-spacing: .05em;
			padding-right: 34px;
			&:after{
				content: "";
				width: 35px;
				height: 4px;
				display: block;
				position: absolute;
				right: -5px;
				top: 50%;
				@include transform(translate(0,-50%));
				@include bg-img(url(../img/arrw-R@3x.png),auto 4px,right center); //$url,$size,$position,$repeat
			}
		}
		ul{
			vertical-align: top;
			white-space: nowrap;
			display: inline-block;
			font-size: 0;
			li{
				display: inline-block;
				margin-left: 10px;
				height: 34px;
				width: 34px;
				border-radius: 50%;
				background: $txt;
				vertical-align: top;
				a{
					display: block;
					width:100%;
					height:100%;
					position: relative;
					img{
						@include centering;
						@include transform(translate(-50%,-50%) scale(.8));
					}
				}
			}
		}
	}
}

p.copyright{
	line-height: 50px;
	text-align: center;
	font-size: 1rem;
	border-top: $line;
	background: #fff;
}


/* !!------------------------------------ */
/* !! 下層ページ共通 */
nav#path{
	display: block;
	position: relative;
	padding:0;
	height: 30px;
	background: #efefef;
	overflow: auto;
	ul{
		position: relative;;
		padding:0 10px ;
		top: 0;left: 0;
		margin: 0;
		line-height: 30px;
		font-size: 12px;
		z-index: 1;
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		li{
			display: inline-block;
			letter-spacing: .05em;
			white-space: nowrap;
			&:after{
				content: "＞";
				display: inline-block;
				margin: 0 15px;			
			}
			&:last-of-type{
				&:after{
					content: none;
										
				}
			}
		}
	}
}

h1.sec{
	margin-top:0*$px;
	margin-bottom: 20*$px;
}



#page-mv{
	min-height: calc(100vh - 150px);
	position: relative;
	padding: 0;
	padding-bottom: 100px;
	&:after{
		content: "";
		width: 150vw !important;
		height: 186px !important;
		@include transform(rotate(338deg)!important);				
		@include bg-img(url(../img/grade1@2x.png),cover); //$url,$size,$position,$repeat
		position: absolute;
		top: 0;right: 0;
		z-index: 1;
		transform-origin: right top;
	}
	.img{
		//display: none;
		position: absolute;
		top:auto;right: 0;
		bottom: 0;
		z-index: 0;
		width: 100%;
		height: 70%;
		background-color: #eee;
	}
	
	.txt{
		position:relative;
		background: none;
		background: #fff;
		width: auto;
		margin: 0 30*$px !important;
		padding: 30*$px;

		right: 0;top:0;
		@include transform(translateY(0));
		margin-right:30px;
		margin-left: 30px;
		z-index: 2;
		h1.sec{
			text-align: left;
		}
		.inner{
			position: relative;
			z-index: 1;
		}
		.lead{
			font-size: 1.4rem;
			line-height: 2;
			strong{
				display: block;
				font-size: 2rem;
				font-weight: bold;
				line-height: 1.6;
				margin-bottom: 10px;
				
			}
		}
		.sign{
			text-align: right;
			margin-top: 15px;
			line-height: 2;
		}
		&:after{
			content: none;
		}
	}
	.bnr-contact{
		bottom: 50*$px;
		right: 50*$px;
		position: absolute;
		z-index: 1;	
	}

}

main.lower{
	overflow: hidden;
	> section {
		.inner {
			max-width: 1000px;
			margin: 0 auto;
		}
	}
}

/* !!------------------------------------ */
/* !! SRB TECHコンサルティングとは */
main#about-srbtech{
	
#page-mv{
	
	&:after{
		height: 186px;
		@include transform(rotate(338deg));				
	}
	.img{
		@include bg-img(url(../../about-srbtech/img/mv@2x.jpg),cover); //$url,$size,$position,$repeat
	}

}

/* !! - - -  */
/* !! 中国事業で成功するために必要な要素 */

section#essentials{
	padding: 50*$px 0;
	
	h2{
		font-size: 2rem;
		line-height: 1.5;
		padding: 0 30*$px;
		text-align:center;
		i{
			@include side-kakko(10px);
			display: inline-block;
			line-height: 1.5;
			padding: 0 30px;
		}
		
	}
	
	.row1{
		position: relative;
		margin-top: 50px;
		padding-top: 0px;
		.img-wrap{
			//display: none;
			position: absolute;
			top: 0;left: 0;
			width: 100%;
			z-index: 0;
		}
		.img{
			width: 100%;
			height: 165px;
			-webkit-background-size: cover !important;
			background-size: cover !important;
			&._1{
				@include bg-img(url(../../about-srbtech/img/img1@2x.jpg));
			}
			&._2{
				@include bg-img(url(../../about-srbtech/img/img2@2x.jpg));
			}
		}
		
		.txt1{
			
			max-width: 1000px;
			display: block;
			align-items: center;
			position: relative;
			z-index: 1;
			margin: 0;
			margin-top: 0;
			padding: 50px 30*$px;
			.numBox{
				width: 100%;
				height: 80px;
				background: $base;
				display: flex;
				align-items: center;
				text-align: center;
				justify-content: center;
				color: #fff;
				font-weight: bold;
				font-size: 2rem;
				line-height: 1;
				.num{
					@include BBS;
					font-size: 6rem;
					font-weight: normal;
					margin-left: 20px;
					i{
						font-size: 0.6em;
					}
				}
			}
			.mid{
				font-size: 0;
				color: #fff;
				z-index: 1;
				text-align: center;
				margin: 10px 0;
				height: 50px;
				strong{
					font-weight: 700;
					font-size: 3rem;
					//line-height: 50px;
					text-shadow: 0 0 4px #000;
				}
				img{
					display: inline-block;
					top: 50%;
					@include transform(translateY(-50%));
					position: relative;
					margin: 0 34px;
				}
			}
			
		}
		.txt2{
			max-width: 1000px;
			margin: 0 auto;
			position: relative;
			z-index: 1;
			background: #fff;
			padding: 30*$px;
			text-align: justify;
			p{
				text-align: justify;
				line-height: 2;
				font-size: 1.6rem;
				letter-spacing: .1em;
				strong{
					@include marker1;
				}
			}
		}
		
	}//.row1
	
	.row2{
		position: relative;
		z-index: 1;
		display: block;
		.img{
			height: auto;
			width: 100%;
			-webkit-background-size: cover !important;
			background-size: cover !important;
			background: #eee;
			position: relative;
			padding: 30*$px;
			.inner{
				background: #FFF;
				width: 100%;
				padding: 15*$px;
				height: auto;
				position: relative;
				z-index: 1;
				top: 0 !important;
				left: 0 !important;
				bottom: 0 !important;
				right: 0 !important;
				text-align: center;
				p{
					@include centering;
					@include transform(translate(0,0));
					position: static;
					white-space: normal;
					font-size: 1.4rem;
					line-height: 1.67;
					text-align: justify !important;
					br{
						display: none;
					}
				}
			}
			&._1{
				@include bg-img(url(../../about-srbtech/img/img3@2x.jpg)); //$url,$size,$position,$repeat
				.inner{top: 72px;right: 80px;}
			}
			&._2{
				@include bg-img(url(../../about-srbtech/img/img4@2x.jpg)); //$url,$size,$position,$repeat
				.inner{top: 72px;left: 80px;}
			}
			&._3{
				@include bg-img(url(../../about-srbtech/img/img5@2x.jpg)); //$url,$size,$position,$repeat
				.inner{bottom: 72px;right: 80px;}
			}
			&._4{
				@include bg-img(url(../../about-srbtech/img/img6@2x.jpg)); //$url,$size,$position,$repeat
				.inner{bottom: 72px;left: 80px;}
			}
		}
		.summary{
			width: 100%;
			height: auto;
			background: $base;
			color: #fff;
			position: relative;
			//@include centering;
			top: 0;left: 0;
			@include transform(translate(0,0));
			p{
				@include centering;
				position: relative;
				top: 0;left: 0;
				@include transform(translate(0,0));
				white-space: normal;
				line-height: 2;
				font-size: 1.6rem;
				padding: 30*$px;
				strong{
					@include marker1(#fff);
					color: #fff !important;
				}
			}
		}
		
	}//.row2
	
}//section#essentials
	
	
}//main#about-srbtech



/* !!------------------------------------ */
/* !! 事業の特徴 */
main#features{
	
#page-mv{
	
	&:after{
		height: 516px;
		@include transform(rotate(349deg));				
	}
	.img{
		@include bg-img(url(../../features/img/mv@2x.jpg),cover); //$url,$size,$position,$repeat
	}
	z-index: 3;
}


/* !! - - -  */
/* !! 情報とネットワーク */
section#information-network{
	position: relative;
	z-index: 2;
	background: #fff;
	padding: 50*$px 0;
	padding-bottom: 0;
	background: $gray-lite2;
	&:after{
		content: "";
		height: 1px;
		background: $txt;	
		display: block;
		margin: 0 auto;
		max-width: 1266px;
		width: auto;
		margin: 0 15*$px;
		position: relative;
		bottom: 0;
		left: 0px;
		@include transform(translateX(0));			
	}
	h2{
		text-align: center;
		
		i{
			font-weight: 600;
			font-size: 2.4rem;
		}
	}
	
	.row1{
		justify-content: center;
		margin-top: 30*$px;
		margin-bottom: 50*$px;
		padding: 0 30*$px !important;
		display: block;
		background: $gray-lite2;
		>div{
			position: relative;
			&._2{
				margin-left: 0px;
				margin-top: 30*$px;
			}
			h3{
				max-width: 100%;
				margin: 0 auto;
				line-height: 40px;
				@include side-kakko(10px);
				text-align: center;
				font-size: 1.6rem;
				margin-bottom: 20*$px;
			}

			.rate{
				margin: 0 auto;
				margin-top: 20*$px;
				width: 100%;
				height: 155*$px;
				@include bg-img(url(../../img/stat-bg@3x.png)); //$url,$size,$position,$repeat
				>div{
					position: relative;
					width: 135*$px;
					height: 155*$px;
					.inner{
						@include centering;
						color: #fff;
						.num{
							font-size: 50*$px;
							line-height: .8;
							@include BBS;
							text-align: center;
							i{
								font-size: 28*$px;
							}
							&:after{
								content: "";
								width: 30px;
								height: 1px;
								display: block;
								background: #fff;
								margin: 10px auto;
							}
						}
						.txt{
							line-height: 1;
							font-size: 1.4rem;
							text-align: center;
							white-space: nowrap;
						}
					}
				}
			}

			ul.idx{
				justify-content: space-between;
				flex-wrap: wrap;
				width: 100%;
				//margin-top: 20*$px;
				li{
					width: calc((100% - #{15*$px}) / 2);
					height: 60px;
					background: $base;
					color: #fff;
					align-items: center;
					justify-content: center;
					display: flex;
					text-align: center;
					margin-top:0;
					margin-bottom: 15*$px;
					font-size: 1.4rem;
					font-weight: bold;
					&:nth-of-type(n + 3){
						margin-bottom: 0;
					}
				}
			}
			p{
				white-space: normal;
				text-align: justify;
				font-weight: bold;
				margin-top: 20*$px;
				font-size: 1.4rem;
				br{
					display: none;
				}
			}
		}
		
	}//.row1
	
	
	
	.row2{
		background: #fff;
		margin-top: 50*$px;
		.img{
			width: 100%;
			height: 200*$px;
			@include bg-img(url(../../features/img/img1@2x.jpg),cover);
		}
		
		.idx{
			max-width: 1266px;
			margin: 0 30*$px;
			margin-top: -100*$px;
			position: relative;
			z-index: 1;
			.txt{
				background: #fff;
				width: 100%;
				padding: 30*$px;
				h3{
					font-size: 2rem;
					display: block;
					text-align: center;
					line-height: 1;
					margin-bottom: 20*$px;
				}
				p{
					font-size: 1.4rem;
					line-height: 2em;
				}
			}
		}
		
	}//.row2
	
	
	.row3{
		padding: 30*$px 0;
		@include bg-img(url(../../features/img/bg@2x.jpg),cover); //$url,$size,$position,$repeat
		
		.network-map{
			position: relative;
			max-width: 1000px;
			margin: 0 auto;
			width: 100%;
			align-items: center;
			h3{
				width: 150px;
				height: 150px;
				margin: 0 auto;
				position: relative;
				z-index: 1;
				//border-radius: 50%;
				//background: #fff;
				display: flex;
				align-items: center;
				justify-content: center;
				img{
					width: 115px;
					height: auto;
					display: block;
				}
			}
				
			svg{
				width: 50px;
				display: none;
			}
			
			dl{
				&:before{
					content:"";
					width: 2px;
					position: absolute;
					top: 0;
					left: 50%;
					margin-left: -1px;
					z-index: 0;
					height: 100%;
					background: $base;
				}
				width: auto;
				margin: 0 30*$px;
				font-size: 0;
				background: none;
				display: block !important;
				dt{
					z-index: 1;
					position: relative;
					font-size: 1.6rem;
					margin-top: 20*$px;
					color: #FFF;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 100%;
					height: 40px;
					background: $base;
					border-bottom: 1px solid rgba(255, 255, 255, 0.42);
				}
				dd{
					background: #fff;
					position: relative;
					z-index: 1;
					align-items: center;
					display: flex;
					width: 100%;
					font-size: 1.4rem;
					padding-left: 0;
					-webkit-flex-wrap: wrap;
					-moz-flex-wrap: wrap;
					-ms-flex-wrap: wrap;
					-o-flex-wrap: wrap;
					flex-wrap: wrap;text-align: center;
					justify-content: center;
					padding: 10px;
					i{
						margin-right: 0;
						margin: 0 .5em;
					}
					&:nth-of-type(2n){
						background: #fff;
					}
				}
			}
			
		}//.network-map
	}//.row3
	
	
	.row4{
		background: #fff;
		padding: 30*$px;
		padding-bottom: 50*$px;
		h3{
			font-size: 2rem;
			line-height: 1.7;
			margin-bottom: 20px;
			text-align: center;
		}
		p{
			b{
				font-size: 1.4rem;
			}
			line-height: 2;
			font-size: 1.4rem;
		}
		
	}//.row4
	
}//section#information-netowork


/* !! - - -  */
/* !! 戦略パートナー */
section#strategy-partner{
	
	padding: 50*$px 0;
	
	h2{
		i{
			font-weight: bold;
		}
	}
	
	.row1{
		margin-top: 30*$px;
		padding: 0 30*$px;
		h3{
			font-weight: bold;
			line-height: 1;
			margin-bottom: 20px;
			font-size: 1.6rem;
		}
		p{
			line-height: 2;
			font-size: 1.4rem;
		}
		.img{
			height:200*$px;
			margin: 30*$px 0;
			@include fitW(30*$px);
			@include bg-img(url(../../features/img/img2@2x.jpg),cover); //$url,$size,$position,$repeat
		}
	}//.row1
	
	.row2{
		padding: 0 30*$px;
		
		h3{
			font-weight: bold;
			line-height: 1;
			margin-bottom: 45*$px;
			font-size: 2rem;
			text-align: center;
		}
		
		.inner >div{
			border: 1px solid $txt;
			padding: 30*$px !important;
			position: relative;
			z-index: 1;
			margin-top: 40px;
			.label{
				background: $base;
				width: 250*$px;
				color: #fff;
				font-size:2rem;
				font-weight: bold;
				padding: 20px 0;
				position: absolute;
				top: 0;
				left: 50%;
				min-height: 0;
				
				@include transform(translate(-50%,-50%));
				p{
					position: static;
					@include centering;
					white-space: nowrap;
				}
				i{
					display: inline;
					width: 100%;
					font-size: 1.8rem;
					margin: 0 auto;
					text-align: center;
					line-height: 1.1;
					min-height: .5em;
					letter-spacing: .1em;
				}
			}
			ul{
				li{
					position: relative;
					padding-left: 0;
					font-size: 1.4rem;
					line-height: 1.6;
					margin-bottom: 25px;
					text-align: justify;
					&:last-of-type{
						margin-bottom: 0;
					}
					.img{
						width: 100%;
						position: relative;
						top: 0;
						left: 0;
						margin: 10px 0 !important;
						img{
							margin: 0 auto;
							display: block;
						}
					}
					b{
						display: block;
						text-align: center;
						letter-spacing: .1em;
						line-height: 1;
						margin: 10px 0;
						margin-top: 15px;
						font-size: 1.6rem;
					}
				}
			}
		}
		
	}//.row2
	
}//section#strategy-partner


	
}//main#features


/* !!------------------------------------ */
/* !! サービス内容 */
main#service{
	
#page-mv{
	
	&:after{
		height: 516px;
		@include transform(rotate(349deg));			
	}
	.img{
		@include bg-img(url(../../service/img/mv@2x.jpg),cover); //$url,$size,$position,$repeat
	}

}
	
/* !! - - -  */
/* !! SRB TECHが得意とする業務分野 */

section#field{
	padding: 50*$px 0;
	h2{
		font-size: 2rem;
		text-align: center !important;
		margin-bottom: 30*$px;
	}
	
	.txt{
		width: 100%;
		background: #fff;
		padding: 0 30*$px;
		h3{
			margin-bottom: 30*$px;
			text-align: center;
		}
		h4{
			font-size: 1.6rem;
			text-align: center;
			line-height: 1.5;
			padding: 10px 0;
			@include side-kakko(10px);
			i{
				display: block;
			}
		}
		ul{
			margin-top: 20*$px;
			li{
				width: 100%;
				font-size: 1.6rem;
				padding-left: 30px;
				position: relative;
				list-style: 1px;
				margin-bottom: 10*$px;
				white-space: normal;
				&:before{
					content:"";
					width: 20px;
					height: 20px;
					position: absolute;
					top: 2px;left: 0;
					@include transform(translateY(0));
					@include bg-img(url(../img/anchor@2x.png)); //$url,$size,$position,$repeat
				}
				&.w2{
					width: 100%;
				}
			}
		}
	}
	
	.row1{
		padding-left:0;
		position: relative;
		.img{
			margin-bottom: 30*$px;
			position: relative;
			top: 0;
			right:0;
			height: 200*$px;
			width: 100%;
			@include bg-img(url(../../service/img/img1@2x.jpg),cover); //$url,$size,$position,$repeat
		}
	}
	
	.row2{
		margin-top: 0*$px;
		justify-content: center;
		position: relative;
		display: block;
		>div{
			margin-top: 30*$px;
			h3{
				margin-bottom: 20*$px;
			}
		}
		.txt{
			
			height: 100%;
			z-index: 1;
			background: #fff;
			position: relative;
		}
		.img{
			position: absolute;
			background: #ccc;
			height: 100%;
			top: 0;
			z-index: 0;
			width: 50vw;
		}
		._2{
			position: relative;
			.img{
				right: 0;
			}
		}
		._3{
			position: relative;
			.img{
				left: 0;
			}
		}
	}
	
	.row3{
		display: none;
		margin-top: -140px;
		position: relative;
		z-index: 0;
		margin-bottom: 50px;
		.img{
			width: 50%;
			height: 400px;
			background: #eee;
			&._1{
				@include bg-img(url(../../service/img/img2@2x.jpg),cover)
			}
			&._2{
				@include bg-img(url(../../service/img/img3@2x.jpg),cover)
			}
		}
	}
	
	
	.row4{
		max-width: 1000px;
		margin: 0 auto;
		margin-top: 30*$px;
		display: block;
		>div{
			width: 100%;
			h3{
				text-align: center;
				font-size: 18*$px;
				font-weight: bold;
				background: #ccc;
				line-height: 50px;
				position: relative;
				margin-bottom: 00px;
				i{
					position: relative;
					z-index: 1;
					color: #FFF;
				}
				&:after{
					content: "";
					position: absolute;
					top: 0;
					right: 0;
					width: 100%;
					height: 100%;
					z-index: 0;
					@include bg-img(url(../../service/img/bg@2x.jpg)); //$url,$size,$position,$repeat					
				}
			}
			.img{
				width: 100%;
				float: none;
				margin-bottom: 00px;
				height: 200*$px;
				img{
					width: 100% !important;
					height: auto !important;
				}
			}
			p{
				margin-top: 20px !important;
				line-height: 30px;
				strong{
					@include marker1;
				}
			}
			
			&._4{
				p{
					background: #fff;
					position: relative;
					z-index: 1;
					margin: 0 30*$px;
					padding: 30*$px;
					margin-top: -100*$px !important;
				}
			}
			
			&._5{
				h3{
					text-align: center;
					&:after{
						top: 0;
						right: auto !important;
						left: 0 !important;
						width: 100%;
						height: 100%;
						z-index: 0;
						transform: rotate(180deg);
					}
				}
				.img{
					float: left;
				}
				p{
					margin: 30*$px;
					margin-bottom: 0;
				}
			}
			
		}
	}
	
	.stage{
		text-align: right;
		font-size: 0;
		float: none !important;
		position: relative;
		margin: 30*$px;
		height: auto !important;
		>div{
			height: 60px;
			display: inline-block;
			position: relative;
			i{
				color: #fff;
				font-size: 1.3rem;
				font-weight: bold;
				text-align: left;
				position: absolute;
				left: 20px;
				top: 50%;
				@include transform(translateY(-50%));
			}
		}
		._1{
			width: 100%;
			margin-left: 60px;
			background: #0068B6;
		}
		._2{
			background: #3B87C5;
			margin-left: 30px;
			width: 100%;
		}
		._3{
			background: #6DA4D3;
			width: 100%;
		}
		._4,._5{
			background: rgba(255, 255, 255, 0.8);
			height: 40px;
			border-radius: 35px 0 0 35px;
			position: absolute;
			top: 10px;
			right: 0;
			width: 174px;
			i{
				white-space: nowrap;
				color: $base;
				font-size: 1.2rem;
			}
			&._5{
				width: 200px;
				top: auto;
				bottom: 30px;
				
			}
		}
	}
		
}//section#field


/* !! - - -  */
/* !! 進出手順と弊社のご支援の例 */

section#flow{
	
	padding: 50*$px 30*$px;
	background: $gray-lite2;
	
	h2{
		font-size: 2rem;
		text-align: center;
		line-height: 1;
		margin-bottom: 20*$px;
	}
	.lead{
		p{
			font-size: 1.4rem;
			line-height: 2;
		}
	}
	ul.flow-list{
		margin-top: 30*$px;
		>li{
			padding: 30*$px 0;
			border-top: 2px dotted $txt;
			&:last-of-type{
				border-bottom: 2px dotted $txt;
			}
			h3{
				line-height: 1.5;
				color: $base;
				font-size: 1.8rem;
				margin-bottom: 15px;
				font-weight: normal;
				position: relative;
				padding-left: 20px;
				i{
					font-size: 1.4rem;
					display: block;
				}
				&:before{
					content: "";
					@include disc;
					top: 13px;
				}
			}
			
		}
	}
	
	ul.flow{
		margin-top: 15px;
		padding-left: 0;
		height: auto;
		font-size: 0;
		position: relative;
		overflow: hidden;
		&:before{
			content:"";
			width: 50px;
			height: 33px;
			position: relative;
			display: block;
			left: 0;
			top: 0;
			@include transform(translateY(0));
			margin-bottom: 10px;
		}
		&.jp:before{
			@include bg-img(url(../img/fl_jp@2x.png)); 
		}
		&.cn:before{
			@include bg-img(url(../img/fl_cn@2x.png)); 
		}
		li{
			display: inline-block;
			height: 60px;
			position: relative;
			background: #ccc;
			width: calc((100% + 48px) / 4 );
			margin-left: -16px;
			$d:polygon(0 0,20px 50%,0 100%,calc(100% - 20px) 100%,100% 50%,calc(100% - 20px) 0);
			@include clip-path($d);

			&:nth-of-type(1){
				$d:polygon(0 0,0 100%,calc(100% - 20px) 100%,100% 50%,calc(100% - 20px) 0);
				@include clip-path($d);
				z-index: 4;
				background: #A5AECB;
				margin-left: 0;
				i{
					margin-left: -5px;
				}
				&:after{
					background: #A5AECB;
				}
			}
			&:nth-of-type(2){
				z-index: 3;
				background: #7D89B5;
				&:after{
					background: #7D89B5;
				}
			}
			&:nth-of-type(3){
				z-index: 2;
				background: #54649E;
				&:after{
					background: #54649E;
				}
			}
			&:nth-of-type(4){
				z-index: 1;
				background: #2B3F88;
				&:after{
					background: #2B3F88;
				}
			}
			
			&:before{
				display: none;
			}
			&:after{
				display: none;
			}
			i{
				margin-left: 0;
				white-space: nowrap;
				color: #fff;
				z-index: 2;
				line-height: 1.3;
				font-size: 1rem;
				@include centering;
				font-weight: bold;
				text-align: center;
			}
		}
	}
	
	
	.mention{
		font-size: 1.6rem;
		text-align: justify;
		line-height: 1.5;
		margin-top: 20*$px;
		color: $base;
		font-weight: bold;
	}	
}//section#flow


/* !! - - -  */
/* !! コンサルティング費用の構成 */
section#composition{
	padding: 50*$px 0;
	h2{
		font-size: 2em;
		display: block;
		text-align: center;
		line-height: 1;
		max-width: 1000px;
		margin: 0 auto;
		margin-bottom: 30*$px;
		span{
			display: block;
			margin-top: 10px;
			font-size: 0.75em;
			color: $base;
			line-height: 20px;
			i{
				font-size: .8em;
				display: block;
			}
		}
	}
	
	.row-grp{
		
		>div{
			height: auto;
			margin-top: 0*$px;
			padding-right: 0;
			position: relative;
			.img{
				height: 200*$px;
				background: #eee;
				z-index: 0;
				overflow: hidden;
			}
			&:nth-of-type(2n){
				padding-right: 0;
				padding-left: 0;
				.txt{
					left: auto;
					right: 0;
					margin-left: 30*$px;
					margin-right: 30*$px;
				}
			}
			.txt{
				margin-top: -100*$px;
				margin-left:30*$px;
				margin-right: 30*$px;
				padding: 30*$px;
				background: #fff;
				width: auto;
				position: relative;
				top: 0;@include transform(translateY(0));
				left: 0;
				z-index: 1;
				h3{
					font-size: 1.6rem;
					line-height: 1.4 ;
					margin-bottom: 20px;
					i{
						font-size: 1.4rem;
					}
				}
				ul{
					li{
						position: relative;
						padding-left: 10px;
						font-size: 1.4rem;
						margin-bottom: 10px;
						&:before{
							content:"";
							@include disc($txt,4px);
							top: 12px !important;
						}
					}
				}
				
			}
		}
		
	}//.row-grp
	
	
	.option{
		max-width: 1000px;
		margin: 0 30*$px;
		margin-top: 50px;
		border: 2px solid $base;
		padding: 30*$px;
		color: $base;
		font-size: 1.6rem;
		position: relative;
		white-space: normal;
		text-align: justify;
		&:before{
			content:"";
			width: 120px;
			height: 40px;
			display: block;
			position: absolute;
			top: 0;left: 50%;
			background-color: $base;
			@include transform(translate(-50%,-50%));
			@include bg-img(url(../../service/img/option@2x.png),80px 80px); //$url,$size,$position,$repeat
		}
		h3{
			font-weight: bold;
			font-size: 1.8rem;
			text-align: justify;
			margin-bottom: 10px;
		}
	}

	
}//section#composition

}//main#service

/* !!------------------------------------ */
/* !! コンサルティング事例 */

main#consulting{
	
#page-mv{
	
	height: auto !important;
}

h2{
	font-size: 2rem;
	text-align: center;
	margin-bottom: 30*$px;
}

.case-solution{
	padding: 50*$px 0;
	padding-bottom: 0;
	>.img{
		height: 200*$px;
		background: #eee;
		-webkit-background-size: cover !important;
		background-size: cover !important;
	}
	
	.inner{
		padding: 0;
		margin-top: 20px;
		padding-bottom: 0;
		max-width: 1100px !important;
		margin: 0 30*$px;
		background: #fff;
		margin-top: 30*$px;
	}
	
	&#licence >.img{
		@include bg-img(url(../../consulting/img/img1@2x.jpg));
	}
	
	&#store_etc >.img{
		@include bg-img(url(../../consulting/img/img4@2x.jpg));
	}

	&#disputing >.img{
		@include bg-img(url(../../consulting/img/img7@2x.jpg));
	}

}

//!! case-solution
.case-wrap{
	position: relative;
	margin-bottom: 70px;
	&:last-of-type{
		margin-bottom: 0;
	}
	>div{
		padding-left: 50px;
		position: relative;
		margin-bottom: 50*$px;
		
		&:last-of-type{margin-bottom: 0;}
		>i{
			width: 60px;
			height: 60px;
			background: $base;
			color: #fff;
			display: block;
			font-size: 1.8rem;
			@include BBS;
			@include flex-centering;
			border-radius: 50%;
			letter-spacing: 0;
			position: absolute;
			left: -20*$px;
			top: -15*$px;
		}
		
		
		.fr{
			float: none;
		}
		.arr{
			position: absolute;
			width: 23px;
			left: -2*$px;
			bottom: -25*$px;
			height: calc(100% - #{35*$px});
			overflow: hidden;
			img{
				position: absolute;
				bottom: 0;left: 0;
			}
		}
		&.has-logo{
			padding-right:0;
			>img{
				position: relative;
				top:10px;right: 0;
				float: right;
				width: 40%;
				height: auto;
				margin-left: 15px;
			}
			p{
				overflow: hidden;
				font-size: 11px;
				line-height: 1.4;
			}
		}
		&.case{
			&:before{
				content:"";
				
			}
		}
		&.solution > i{
			background: $blue;
		}
		h3{
			font-size: 2.0rem;
			line-height: 1;
			font-weight: 500;
			border-bottom: 2px dotted $txt;
			padding-bottom: 15px;
			margin-bottom: 8px;
		}
		
		ul{
			li{
				font-size: 1.42rem;
				letter-spacing: 0;
				line-height: 2;
				strong{
					@include marker1;
					font-weight: 600;
					border-bottom-style: solid;
				}
			}
		}
		
		.idx{
			margin-top: 30px;
		}
		
		p:not(.note){
			margin-top: 8px;
			font-size: 1.4rem;
			background: #efefef;
			margin-right: -30*$px;
			margin-left: -40*$px;
			padding: 15*$px;
			padding-right: 30*$px;
			padding-bottom: 0;
			& + .idx{
				margin-left: -40*$px;
				background: #efefef;
				padding: 15px;
				margin-top: 0 !important;
				margin-right: -30*$px;
				padding: 15*$px;
				padding-right: 30*$px;
				display: block;
				img{
					width: 100% !important;
					height: auto !important;
					margin-bottom: 15*$px;
					&:last-of-type{
						margin-bottom: 0;
					}
				}
			}
		}
		.note{
			@include note(1em);
			margin-top: 8px;
			text-align: left;
			font-size: 12px;
		}
	}//.case
}



/* !! - - -  */
/* !! 投資企業事例 */

#investiment-case{
	
	padding: 50*$px 0;
	padding-bottom: 50*$px;

	h2{
		font-size: 1.4rem;
	}
	
	.display{
		max-width: 900px;
		margin: 0 auto;
		margin-top: 30*$px;
		position: relative;
		padding: 0 30*$px;
		h3{
			font-size: 1.8rem;
			text-align: center;
			line-height: 1;
			@include flex-centering;
			position: relative;
			z-index: 1;
			i{
				font-size: 1.4rem;
				display: block;
				margin-top: 5px;
			}
			color: #fff;
			font-size: 18px;
			position: relative;
			top: 0;left: 0;
			width: 100%;
			height: 60px;
			@include bg-img(url(../img/bnr-bg.png),100% auto); //$url,$size,$position,$repeat
		}
		svg{
			display: none;
		}
		ul{
			margin-left: 0;
			font-size: 0;
			padding-left: 50*$px;
			position: relative;
			&:after{
				content:"";
				height: 100%;
				width: 1*$px;
				background: $txt;
				top: -76px;
				left: 25*$px;
				position: absolute;
				z-index: 0;
			}
			li{
				
				width: 100%;
				height: auto;			
				display: block;
				margin-bottom: 0px;
				margin-top: 30*$px;
				color: #fff;
				background: none;
				white-space: nowrap;
				font-size: 1.3rem;
				position: relative;
				text-align: center;
				&:after{
					content:"";
					height: 1px;
					width: 50*$px;
					background: $txt;
					top: 34px;
					left: -25*$px;
					position: absolute;
				}
				i{
					@include flex-centering;
					background: $base;
					width: 100%;
					height: 70px;
					text-align: center;
					margin-bottom: 5px;
					position: relative;
					z-index: 1;
				}
				&:before{
					content:"";
					width: 30px;
					height: 20px;
					position: absolute;
					top: 0;
					left: 0;
					z-index: 2;
					@include transform(translate(-50%,-50%));
				}
				&.jp:before{
					@include bg-img(url(../img/fl_jp@2x.png)); 
				}
				&.cn:before{
					@include bg-img(url(../img/fl_cn@2x.png)); 
				}
				&:last-of-type{
					margin-bottom: 0;
				}
				&.cap{
					display: none;
				}
				span{
					position: relative;
					right: 0px;
					top: auto;bottom:0;
					background: #fff;
					color: $txt;
					height: 36px;
					margin-top: 5px;
					font-size: 1.2rem;
					text-align: left !important;
					width: 100%;
					padding-left:80px;
					border: none !important;
					align-self: flex-start;
					@include transform(translate(0));
					b{
						display: block;
						width: 100%;
						text-align: left;
						font-weight: normal;
					}
					&:before{
						display: none;
					}
					&:after{
						content: "合弁相手";
						position: absolute;
						left: 0;
						top: 0;
						width:70px;
						height: 100%;
						background: #efefef;
						padding:0;
						@include flex-centering;
						line-height: 1;				
					}
				}
			}
		}
	}
	
}//#investiment-case
	
}//main#consulting



/* !!------------------------------------ */
/* !! 実績企業 */

main#performance{
	
#page-mv{
	
	&:after{
		height: 516px;
		@include transform(rotate(349deg));			
	}
	.img{
		@include bg-img(url(../../performance/img/mv@2x.jpg),cover); //$url,$size,$position,$repeat
	}

}

section.table{
	padding: 50*$px 0;
	table{
		max-width: 1000px;
		margin: 0 auto;
		font-size: 1.2rem;
		border: none !important;
		display: block;
		tbody{
			display: block;
		}
		tr{
			position: relative;
			display: block;
			
			background: none;
			padding: 15px 0;
			padding-left:70px;
			&:first-of-type{
				display: none;
			}
			&:nth-of-type(2n){
				background: #efefef;
				td{
					background: #efefef;
					&:first-of-type:after,
					&:last-of-type:after{
						content:none;
					}
				}
			}
		}
		td{
			position: relative;
			background: none;
			border: none !important;
			display: block;
			padding: 0;
			&:after{
				content: none !important;
								
			}
			&:first-of-type{
				padding: 0;
				text-align: center;
				position: absolute;
				top: 0;
				height: 100%;
				width: 50px;
				
				text-align: center;
				border-right:1px solid #ccc !important;
				left: 0;
				z-index: 1;
				padding: 15px 0 !important;
				&:before{
					content:"No.";
					width: auto;
					background: none;
					position: relative;
					border: none;
					color: $txt;
					display: inline !important;
				}			
			}
			height: auto;
			border-right: 0;
			line-height: 2;
			white-space: nowrap;
			padding-left: 70px;
			position: relative;
			margin-bottom: 5px;
			white-space: normal;
			padding-right: 30*$px;
			&:before{
				width: 60px;
				background: none;
				text-align: center;
				position: absolute;
				top: 0;left: 0;
				color: #666;
				height: 100%;
				border: 1px solid #ddd;
				@include flex-centering;
				line-height: 1;
				
			}

			&:nth-of-type(2){
				&:before{
					content:"業  種";
				}
			}
			&:nth-of-type(3){
				&:before{
					content:"資 本 金";
				}
			}
			&:nth-of-type(4){
				&:before{
					content:"市場公開";
				}
			}
			&:last-of-type{
				border-right: 0;
				&:before{
					content:"結  果";
				}
			}
		}
	}
	
}//section.table


}//#performance




/* !!------------------------------------ */
/* !! 会社概要 */
main#company{
	
#page-mv{
	height: auto;
	&:before{
		content:"";
		height: 200*$px;
		width: 100%;
		position: absolute;
		top: 0;left: 0;
		background: #fff;
		z-index: 1;
	}
	.img{
		height: 100%;
		@include bg-img(url(../../company/img/mv@2.jpg),cover); //$url,$size,$position,$repeat
	}
	
	.txt{
		top: 0 !important;
		width: auto !important;
		@include transform(translateY(0));
	}
}

/* !! - - -  */
/* !! パートナー挨拶 */
section.greeting{
	margin-top: 0; 
	padding: 50*$px 30*$px;
	h2.sec{
		text-align: left;
	}
	p{
		margin: 0;
		line-height: 2;
	}
	
	.txt{
		margin-top: 30*$px;
		width: 100%;
	}
	
	.sign{
		text-align: right;
		@include pfix(1.25em);
		padding-top: 30px;
		img{
			position: relative;
			top: -5px;
		}
	}
	
	.img{
		width: 80%;
		margin: 0 auto;
		position: relative;
		top: 0;right: 0;
		margin-top: 30*$px;
		>img{
			width: 100% !important;
			height: auto !important;
		}
	}
	
	.manifest{
		font-size: 1.6rem;
		text-align: justify;
		line-height: 1.6;
		color: $base;
		padding: 20*$px;
		margin-top: 30px;
		border: 2px solid $base;
		
		strong{
			font-weight: bold;
		}
	}
	
}//section.greeting	
	
/* !! - - -  */
/* !! 会社概要 */
	
#outline{
	padding: 50*$px 30*$px;
	background: $gray-lite2;
}

ul.outline{
	margin-top:20*$px;
	>li{
		border-top:0;
		padding: 30*$px 0;
		padding-top: 0px;
		font-size: 1.4rem;
		padding-left: 0;
		position: relative;
		margin-top: 0*$px;
		&:last-of-type{
			border-bottom: 0;
			padding-bottom: 0;
		}
		a.btn-s{
			width: 90%;
			margin: 10px auto;
		}
		>i{
			position: relative;
			line-height:24px;
			top: 0;
			left: 0;
			padding-left: 0;
			padding-left: 10px;
			background: #ccc;
			color: #222;
			font-size: 1.2rem;
			margin-bottom: 10px;
			br{
				display: none;
			}
			display: block;
		}
		div{
			position: relative;
			.toggle{
				position: relative;
				right: 0;
				top: 0px;
				width: 90%;
				margin: 10px auto;
			}
		}
		
		ol{
			line-height: 1.75;
			padding-left: 1.5em;
			li{
				margin-bottom: 10px;
				text-align: justify;
				font-size: 1.4rem;
			}
		}
		
		.idx.add{
			margin-bottom: 0px;
			display: block;
			&:last-of-type{
				margin-top: 20*$px;
			}
			p{
				&:first-of-type{
					//width: 143px;
					margin-right: 10px;
					line-height: 30px;
					position: relative;
					position: absolute;
					left: 0;top: -3px;
					width: 90px;
					&:before{
						content:"";
						width: 100%;
						height: 1px;
						position: absolute;
						top: 50%;left: 0;
						border-bottom: 1px dotted $txt;
					}
					i{
						display: inline-block;
						background: $gray-lite2;
						position: relative;
						padding-right: 6px;
						z-index: 1;
						vertical-align: middle;
					}
				}
				&:nth-of-type(2n){
					margin-left: 100px;
					
				}
			}
		}
		
		.idx._2{
			margin-bottom: 0;
			p{
				white-space: nowrap;
				align-items: center;
				
				&:before{
					content:none!important;
					
				}
			}
			.idx.mid{
				margin-top: 10px;
				margin-bottom: 0;
				margin-left: 0;
				align-items: center;
				justify-content: flex-start;
				img{
					margin-right: 10px;
				}
			}
		}
		
		&.partner{
			min-height: 90px;
		}
	}
	

	
	ul.biography{
		display: none;
		padding-top: 20px;
		padding-left: 70px;
		margin-top: 20px;
		max-width: 628px;
		line-height: 1.86;
		border-top: 1px dotted $txt;
		position: relative;
		li{
			font-size: 1.3rem;
		}
		.hd{
			
			background: $base;
			width: 40px;
			height: calc(100% - 20px);
			position: absolute;
			bottom: 0;left: 0;
			list-style: none;
			i{
				text-align: center;
				width: 1em;
				color: #fff;
				@include centering;
			}
		}
	}
	
	

	
	
}//ul.outline



/* !! - - -  */
/* !! アクセス */
#access{
	
	padding: 50*$px 0;
	
	.map{
		margin-top: 30*$px !important;
		height: auto;
		background: #fff;
		position: relative;
		iframe{
			display: block;
			width: 100%;
			height: 100%;
			height: 300px;
		}
		.txt{
			padding: 20*$px;
			margin: 20*$px 30*$px;
			color: #fff;
			z-index: 1;
			width: auto;height: auro;
			//@include bg-img(url(../../company/img/bg@2x.png)); //$url,$size,$position,$repeat
			position: relative;
			left: 0;
			top:0;
			line-height: 1.75;
			p{
				font-size: 1.2rem;
			}
			b{
				font-size: 18px;
			}
			.btn-more{
				position: absolute;
				bottom: 30px;right: 30px;
			}
		}
	}
	
}//#access


/* !! - - -  */
/* !! 組織図 */

#organization{
	padding-bottom: 0px;
	.inner{
		max-width: 1000px;
	}
	
	#organization-map-zoom{
		overflow: hidden;
		height: auto;
		background: #fff !important;
		margin: 30*$px 15*$px;
		border: $line;
		margin-top: 30*$px;
		position: relative;
		&:after{
			content: "";
			width:100%;
			height:100%;
			border: $line;
			position: absolute;
			top: 0;left: 0;
			z-index: 2;
			pointer-events: none;					
		}
		img{
			width: 100%;
			height: auto;
			z-index: 0;
		}
	}
	
/*
	.organization-map{
		margin-top: 50px;
	}
	.row{
		font-size: 0;
		margin-bottom: 30px;
		z-index: 1;
		position: relative;
		&._1,&._2{
			>div{
				@include bg-img(url(../../company/img/bg@2x.png)); //$url,$size,$position,$repeat
			}
			.base{
				background-image: none;
				background: $base !important;
				ul{
					position: absolute;
					bottom: -8px;left: 0;
					font-size: 15px;
					color: $txt;
					@include transform(translateY(100%));
				}
			}
		}
		&:last-of-type{
			margin-bottom: 0;
		}
		>div{
			vertical-align: top;
			width: calc((100% - 120px) / 5);
			height: 70px;
			background: #ccc;
			position: relative;
			color: #fff;
			display: inline-block;
			margin-right: 30px;
			i{
				@include centering;
				font-size: 16px;
				display: block;
				white-space: nowrap;
				text-align: center;
			}
		}
		&._2{
			text-align: center;
			margin-bottom: 0;
			>div{
				margin: 0;
			}
		}
	}
*/
	
/*
	.line-wrap{
		width: 100%;
		position: relative;
		height: 60px;
		svg{
			position: absolute;
			bottom: 0;left: 0;
		}
	}
	
	.list-grp{
		position: relative;
		font-size: 14px;
		.side{
			position: absolute;
			width: 134px;
			left: 0;
			top: 70px;
			@include transform(translateX(-100%));
			>li{
				padding-top: 20px;
				border-bottom: 1px solid #fff;
				padding-bottom: 8px;
				line-height: 1;
				font-weight: bold;
			}
		}
		.idx {
			>div{
				width: calc((100% - 90px) / 4);
				border-bottom: 1px solid #ccc;
				.hd{
					position: relative;
					background: $base;
					position: relative;
					height: 70px;
					i{
						@include centering;
						color: #fff;
						font-size: 1.6rem;
						white-space: nowrap;
						text-align: center;
					}
					&:before{
						content:"";
						width: 30px;
						height: 20px;
						position: absolute;
						top: 0;
						left: 0;
						@include transform(translate(-50%,-50%));
					}
					&.jp:before{
						@include bg-img(url(../img/fl_jp@2x.png)); 
					}
					&.cn:before{
						@include bg-img(url(../img/fl_cn@2x.png)); 
					}
				}
				>ul{
					>li{
						padding-top: 20px;
						border-bottom: 1px solid #ccc;
						padding-bottom: 8px;
						line-height: 1;
						span{
							display: inline-block;
							width: 50%;
						}
						
						&:last-of-type{
							border-bottom: none;
						}
						ul{
							line-height: 1.7;
							padding-bottom: 10px;
							li{
								font-size: 1.4rem;
								
								i{
									font-size: 1.2rem;
								}
							}
						}
					}
				}
			}
		}
	}
*/
}//#organization
	
}//main#conmpany




/* !!------------------------------------ */
/* !! お問い合わせ */
main#contact{
	margin-bottom: 50*$px;
	padding-top: 50*$px;
	.lead{
		max-width: 800px;
		margin: 0 30*$px;
		margin-bottom: 50*$px;
		padding-bottom: 0;
		border-bottom: 0;
		font-size: 1.6rem;
		line-height: 1.7;
		white-space: normal;
		i{
			color: $red;
		}
		.font-large & {
			white-space: normal;
		}
	}
		
	#form-wrap{
		margin-top: 0px;
		background: #eee;
		position: relative;
		padding: 15*$px;
		@include bg-img(url(../../contact/img/form-bg@2x.png),cover); //$url,$size,$position,$repeat
		h2{
			text-align: center;
			line-height: 1;
			font-size: 18*$px;
			margin: 5*$px 0 20*$px 0;
		}
		
		#mailformpro{
			margin: 0 auto;
			position: relative;
			max-width: 1000px;
			background: #FFF !important;
			padding: 15*$px;
		}
		
		
		.idx{
			display: block;
			>div{
				width: 100%;
				margin-bottom: 20*$px;
				p{
					line-height: 1;
					font-size: 1.4rem;
					margin-bottom: 10px;
					span{
						color: $red;
					}
				}
				&.txtarea{
					width: 100% !important;
					textarea{
						height: 200px;
						width: 100%;
						display: block;
						border: 1px solid $txt;
					}
				}
			}
		}
		
		
		input[type="text"],
		input[type="email"],
		input[type="tel"],label{
			display: block;
			padding: 0;
			border: none;
			max-width: 100%;
			padding: 0 8px;
			font-size: 14px;
			height: 30px;
			width: 100% !important;
			border-radius: 0 !important;
			box-shadow: none !important;
			border: 1px solid $txt;
			margin: 0;
			&:focus{
				background: #ebfbff !important;
				outline: none;
			}
		}
		label{
			height: 50px;
			display: block;
			padding: 0 10px;
			width: 100% !important;
			margin-bottom: 15px;
			span{
				line-height: 1;
				display: block;
				font-size: 1.4rem;
				top: 50%;position: relative;
				@include transform(translateY(-50%));
				padding: 0 30px;
				padding-left: 30px;
				&.checked:after{
					opacity: 1;
				}
				&:after{
					opacity: 0;
					content: "";
					display: block;
					width: 20px;
					border: 1px solid $txt;
					border-width: 0 0 1px 1px;
					height: 10px;
					position: absolute;
					top: -4px;left: 4px;
					
					@include transform(rotate(-45deg));					
				}
				input{
					appearance: none;
					display: block;
					width: 20px;
					height: 20px;
					background: none;
					border-radius: 0;
					border: 1px solid $txt;
					position: absolute;
					top: -3px;left: 0;
				}
			}
		}
		
		.select{
			position: relative;
			padding-left:0;
			margin: 20*$px 0;
			i{
				line-height: 40px;
				font-size: 14px;
				position: static;
				top: 0;left: 0;
			}
			.wrap{				
				height: 40px;
				position: relative;
				&:after{
					content: "";
					@include anchor();//$color,$size,$Bw,$left,$right
					border-width: 0 0 1px 1px;
					margin-top: -3px;
									
				}
				select{
					font-size: 15px;
					appearance: none;
					width: 100%;
					height: 100%;
					display: block;
					max-width: 1000px;
					background: none;
					box-shadow: none;
					line-height: 30px;
					border: 1px solid $txt;
					margin: 0;
					border-radius: 0;
					&:focus{
						outline: none;
					}
				}
			}
		}
		
		textarea{
			padding:10px;
			border: none;
			max-width: 100%;
			height: 100px !important;
			width: 100% !important;
			font-size: 14px;
			border-radius: 0 !important;
			box-shadow: none !important;
			&:focus{
				background: #ebfbff !important;
				outline: none;
			}
		}
	
		
		.mfp_err{
			background: none;
			padding-left: 0;
			font-size: 12px;
			letter-spacing: .1em;
		}
		
		.note{
			text-align: center;
			font-size: 1.4rem;
			line-height: 1.7;
			margin-bottom:20*$px;
		}
		
		.btn-wrap{
			text-align: center;
		}
		
		.btn-wrap input,
		.mfp_element_button{
			border-radius: 0;
			text-shadow: none;
			appearance: none;
			display: inline-block;
			margin: 0 15px;
			background: $base;
			color: #fff;
			width: 250px;
			line-height: 50px;
			font-weight: bold;
			border: none;
			font-size: 16px;
			padding: 0;
			margin-bottom: 15px;
			&#mfp_button_cancel{
				background: #000;
			}
			&[type=reset]{
				background: #000;
			}
		}
		
	}
	#mfp_phase_confirm{
		max-width: 1000px;
		margin: 0 auto;
		background: #fff;
		position: relative;
		//top: -100px;
	}
	#mfp_phase_confirm_inner{
		overflow: hidden;
		padding: 15*$px;
		margin-top: 0px;
		&:empty{
			border: none;
			display: none;
		}
		h4{
			font-size: 18px;
			max-width: 1000px;
			margin: 0 auto;
			padding-top: 0;
			margin: 5*$px 0 20*$px 0 !important;
		}
		
		#mfp_confirm_table{
		
			border: 1px dashed #aaa;
			margin-bottom: 30px;
			border-collapse: collapse;
			width: 100%;
			td,th{
				font-size: 1.4rem;
				padding: 10px;
				font-weight: normal;
				background: #FFF !important;
				border-style: dashed;
				border: 1px dashed #aaa;
			}
		}
		.mfp_buttons{
			padding: 0;
		}
	}
	
	#mfp_warning{
		@include transform(translateY(-50%));
		z-index: 2;
		background: none;
		font-size: 12px;
		border: none;
		color: $red;
		position: relative;
		top: 0;
		text-align: center;
		left:auto;
		margin: 0 auto !important;
		height: 50px;
		top: -50px;
		max-width: 800px;
	}
	
	
	//!! - thanks
	&.thanks{
		text-align: center;
		height:auto;
		min-height: 0;
		position: relative;
		p.accept{
			
			box-sizing: border-box;
			//border: 1px dashed #aaa;
			//@include centering;
			position: static !important;
			transform: none !important;
			display: block;
			left: 0;
			margin: 0 30*$px;
			margin-top: 24px;
			padding: 15px;
			width: auto !important;
			font-size: 1.4rem;
			line-height: 1.8;
			white-space: normal;
			b{
				display: block;
				font-size: 1.2em;
				letter-spacing: .1em;
			}
		}
	}
	
}//main#contact

/* !!------------------------------------ */
/* !! よくある質問 */

main#faq{
	padding-bottom: 50*$px;
	min-height:0;
	ul.filter{
		max-width: 1000px;
		margin: 0 30*$px;
		margin-top: 30*$px;
		li{
			width: calc((100% - 20px) / 3);
			height: 30*$px;
			border: 1px solid $txt;
			font-size: 1.4rem;
			white-space: nowrap;
			@include flex-centering;
			letter-spacing: .1em;
			cursor: pointer;
			&.fade{
				border: 1px dashed #bbb;
				color: #bbb;
			}
			&.current{
				border-width: 2px;
			}
		}
		
	}//ul.select
	
	dl{
		max-width: 1000px;
		margin: 0 30*$px;
		dt{
			margin-top: 30*$px;
			font-size: 1.6rem;
			font-weight: 600;
			line-height: 25px;
			padding-bottom: 15px;
			border-bottom: 2px dotted $txt;
			padding-left:0;
			padding-right: 30px;
			padding-top: 30px;
			position: relative;
			cursor: pointer;
			&:before{
				content:"";
				width: 80px;
				height: 25px;
				background: #eee;
				@include BBS;
				font-size: 14px;
				letter-spacing: .1em;
				@include flex-centering;
				color: $txt;
				position: absolute;
				top: 0;left: 0;
			}
			&:after{
				transition: 100ms;
				content: "";
				width: 20px;
				height: 20px;
				border-radius: 50%;
				position: absolute;
				right: 0;top: 50%;
				@include transform(translateY(-50%));
				background: $base;
				@include bg-img(url(../../faq/img/plus@2x.png),10px); //$url,$size,$position,$repeat
			}
			&.opend:after{
				@include bg-img(url(../../faq/img/minus@2x.png),10px 2px); //$url,$size,$position,$repeat
			}
		}
		
		dd{
			display: none;
			margin-bottom: 50*$px;
			p{
				font-size: 1.2rem;
				line-height: 1.86;
				margin-top: .5em;
			}
			ul.flow{
				margin-top: 20*$px;
				li{
					display: block;
					margin-top: 20*$px;
					img{
						display: block;
						width: 100% !important;
						height: auto !important;
					}
					p{
						@include flex-centering;
						display: block !important;
						font-size: 1.2rem;
						text-align: left;
						margin: 0;
						@include pfix(.88em);
						width: auto;
						margin-bottom: 10*$px;
						white-space: normal;
						br{display: none;}
						b{
							font-size: 1.5rem;
							display: block;
							font-weight: normal;
							font-weight: 500;
						}
					}
				}
			}
		}
		&.merit dt:before{
			content: "MERIT";
			background: #00A2DD;
			color: #fff;
		}
		&.risk dt:before{
			content: "RISK";
			background: #ECE034;
		}
		&.method dt:before{
			content: "METHOD";
			background: #8EC21E;
			color: #fff;
		}
	}
	
	
}//main#faq



/* !!------------------------------------ */
/* !! サイトポリシー プライバシーポリシー*/
main.policy{
	padding-bottom: 50*$px;
	.lead{
		max-width: 1000px;
		margin: 0 30*$px;
		line-height: 1.88;
		br{
			display: none;
		}
	}
	
	dl{
		max-width: 1000px;
		margin: 0 30*$px;
		dt{
			margin-top: 30*$px;
			font-size: 1.6rem;
			font-weight: 600;
			line-height: 25px;
			padding-bottom: 10px;
			//border-bottom: 2px dotted $txt;
			padding-left:0;
			padding-right: 30px;
			padding-top: 0;
			position: relative;
		}
		
		dd{
			margin-bottom: 0*$px;
			p{
				font-size: 1.2rem;
				line-height: 1.86;
				margin-top: .5em;
			}
			ul{
				font-size: 1.2rem;
			}
		}
	}



}//main.policy

/* !!------------------------------------ */
/* !! ニュース&トピックス */
main#news-and-topics{
	
	min-height:0;
	
}//main#news-and-topics

/* !! - - -  */
/* !! index */

ul.post-list.news-and-topics{
	max-width: 1000px;
	margin: 0 auto;
	margin-top: 25px;
	padding: 0 30*$px;
	i.cat{
		background: $base;
		width: 80px;
		height: 20px;
		display: block;
		@include BBS;
		line-height: 1;
		color: #fff;
		font-size: 1.4rem;
		letter-spacing: .1em;
		a{
			display: block;
			@include flex-centering;
			width:100%;
			height:100%;
			color: #fff;
			font-size: 1.4rem;
		}
	}

	li{
		border-bottom: 1px solid $txt;
		margin-bottom: 0;
		position: relative;
		padding: 15px 0;
		padding-bottom: 0;
		i{
			position: relative;
			top: 0;
			display: block;
			background: $base;
			width: 80px;
			height: 20px;
			@include BBS;
			line-height: 1;
			
			color: #fff;
			font-size: 1.4rem;
			letter-spacing: .1em;
			a{
				display: block;
				@include flex-centering;
				width:100%;
				height:100%;
			}
		}
		>a{
			font-size: 1.4rem;
			padding: 10px 0;
			padding-left:0px;
			padding-right: 30px;
			display: block;
			&:hover{
				background: #efefef;
			}
			&:after{
				content: "";
				@include anchor($txt,$right:0);//$color,$size,$Bw,$left,$right					
			}
		}
	}
}


/* !! ページャー */
.wp-pagenavi{
	margin: 40px 30*$px;
	margin-bottom: 50px;
	text-align: center;
	padding: 0;
	position: relative;
	width:auto;
	color: #fff;
	font-size: 0;
	margin-top: 40px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: center;
	a,span{
		font-size: 16px !important;
		width: 40px;
		height: 40px;
		background:#959EC2 ;
		display: inline-block;
		vertical-align: top;
		text-align: center;
		margin: 0 4px;
		@include BBS;
		line-height: 40px;
		&.current{
			background: $base;
			color: #fff;
		}
	}
	.pages{
		display: none;
	}
	.previouspostslink{
	
		left: 0;
		top: 0;left: 0;
		font-size: 0;
		width: 30px;height: 40px;
		background: none;
		display: inline-block;
		transform: rotate(180deg);
		margin-right: 0;
		position: absolute;
		right: 0;
		
		&:after{
			content: "";
			margin-top: -2px;
			@include anchor($base,15px,3px,auto,0);//$color,$size,$Bw,$left,$right
		}
	}
	.nextpostslink{
		top: 0;right: 0;
		font-size: 0;
		position: relative;
		background: none;
		width: 30px;height: 40px;
		display: inline-flex;
		margin-left: 0;
		position: absolute;
		right: 0;

		&:after{
			content: "";
			@include anchor($base,15px,3px,auto,0);//$color,$size,$Bw,$left,$right
			margin-top: -2px;
		}
	}
	.last,.first{
		display: none;
	}
}


/* !! - - -  */
/* !! single */

#news-and-topics.single{
	overflow: hidden;
	padding-top: 50*$px;
	article{
		max-width: 1000px;
		margin: 0 30*$px;
		margin-top: 0px;
		
		h1.ttl{
			font-size: 2rem;
			position: relative;
			padding-left: 0px;
			margin-bottom: 20*$px;
			line-height: 1.5;
			i{
				position: relative;
				top: 0px;left: 0;
				background: $base;
				width: 80px;
				height: 20px;
				@include BBS;
				line-height: 1;
				color: #fff;
				font-size: 1.4rem;
				letter-spacing: .1em;
				display: block;
				margin-bottom: 10px;
				a{
					display: block;
					@include flex-centering;
					width:100%;
					height:100%;
					color: #fff;
					font-size: 16px;
				}
			}
		}
		p{
			font-size: 1.6rem;
			line-height: 1.88;
		}
	}
	
	
	//!! - 前後の記事
	.prev-next{
		margin-top: 50*$px;
		background: #F7F8F8;
		padding: 20*$px 0;
		ul{
			max-width: 1000px;
			margin: 0 ;
			display: block;
			padding: 0 30*$px;
		}
		li{
			width: 100%;
			@include flex-centering;
			justify-content: flex-start;
			align-items: flex-start;
			padding-bottom:0px;
			position: relative;
			margin-bottom: 20*$px;
			&:last-of-type{
				margin-bottom: 0;
			}
			a.anchor{
				position: absolute;
				//background: #eee;
				width: 40px;
				height: 40px;
				top: 50%;
				left: 5px;
				opacity: .5;
				@include transform(translate(-100%,-50%) rotate(180deg));
				&:after{
					content: "";
					@include anchor($base,12px,3px,$right:12px);//$color,$size,$Bw,$left,$right					
					margin-top: -2px;
				}
				&.next{
					top: 50%;
					left: auto;
					right: 5px;
					@include transform(translate(100%,-50%));
				}
			}
			i{
				background: $base;
				width: 80px;
				height: 20px;
				display: block;
				@include BBS;
				line-height: 1;
				color: #fff;
				font-size: 1.4rem;
				letter-spacing: .1em;
				a{
					display: block;
					@include flex-centering;
					width:100%;
					height:100%;
					color: #fff;
					font-size: 1.4rem;
				}
			}
			
			p.ttl{
				margin: 10px 0;
				align-self: flex-start;
				font-weight: bold;
				font-size: 1.8rem;
				font-weight: 500;
			}

			.btn-more{
				height: 30px;
				line-height: 28px;
				width: 150px;
				margin: 0 auto;
				display: block;
				margin-right: 0;
				position: relative;
				bottom: 0;right: 0;
				margin-bottom: 0;
			}
		}
	}//.prev-next
	
	a.backToIndex{
		width: 300px;
		height: 50px;
		font-size: 1.6rem;
		background: $base;
		color: #fff;
		display: block;
		@include flex-centering;
		margin: 50px auto;
		letter-spacing: .2em;
	}
	
}//#news-and-topics.single

	a.backToIndex{
		width: 300px;
		height: 50px;
		font-size: 1.6rem;
		background: $base;
		color: #fff;
		display: block;
		@include flex-centering;
		margin: 50px auto;
		letter-spacing: .2em;
	}


/* !!------------------------------------ */
/* !! セミナー＆イベント */
main#seminar-and-event{
	overflow: hidden;
	width: auto;
	padding-left: 0;
	height: auto !important;
	min-width: 320px !important;
	position: relative;
	min-height:0;

}//main#seminar-and-event

ul.post-list.seminar-and-event{
	max-width: 1000px;
	margin: 0;
	margin-top: 50*$px;
	width: 100%;
	padding: 0 30*$px;
	margin-bottom: 50*$px;
	li{
		width:100%;
		margin-bottom: 30*$px;
		position: relative;
		padding-bottom: 30*$px;
		&:nth-last-of-type(-n + 3){
			padding-bottom: 30*$px;
			margin-bottom: 30*$px;
		}
		&:last-of-type{
			margin-bottom: 0;
		}
		.img{
			height: auto;
			background: #efefef;
			min-height: 209*$px;
			a{display: block;}
			img{
				width: 100% !important;
				height: auto !important;
			}
		}
		.txt{
			position: relative;
			z-index: 1;
			padding: 20px;
			margin-left: 30px;
			background: #fff;
			margin-top: -50px;
			i{
				background: $base;
				width: 80px;
				height: 20px;
				display: block;
				@include BBS;
				line-height: 1;
				color: #fff;
				font-size: 1.4rem;
				letter-spacing: .1em;
				position: absolute;
				top: 0;right: 0;
				@include transform(translateY(-50%));
				a{
					display: block;
					@include flex-centering;
					width:100%;
					height:100%;
					color: #fff;
					font-size: 16px;
				}
			}
			p{
				font-size: 1.8rem;
				font-weight: 600;
				line-height: 1.67;
				@include pfix(.67em);
			}
		}
		.btn-more{
			display: block;
			position: absolute;
			bottom: 0;right: 0;
			z-index: 1;
			line-height: 30px;
			height: 30px;
			width: 150px;
		}
	}
	
}//ul.post-list.seminar-and-event


/* !! - - -  */
/* !! single */
main#seminar-and-event.single{
	padding: 50*$px 0*$px;
	padding-bottom: 0;
	article{
		padding: 0 30*$px;
	}
	h1.ttl{
		font-size: 2rem;
		position: relative;
		padding-left: 0px;
		margin-bottom: 20*$px;
		line-height: 1.5;
	}	
	i.cat{
		background: $base;
		width: 80px;
		height: 20px;
		display: block;
		@include BBS;
		line-height: 1;
		color: #fff;
		font-size: 1.4rem;
		letter-spacing: .1em;
		margin-bottom: 10px;
		a{
			display: block;
			@include flex-centering;
			width:100%;
			height:100%;
			color: #fff;
			font-size: 1.4rem;
		}
	}


	.mv{
		margin-top: 0;
		img{
			margin: 0 auto;
			display: block;
			height: auto;
			max-width: 100%;
			margin-bottom: 30*$px;
		}
	}
	
	section.outline{
		margin-top: 50*$px;
		background: $gray-lite2;
		h2{
			line-height: 1;
			top: 0;left: 0;
			font-size: 2rem;
			letter-spacing: .1em;
			//@include transform(translateY(-	100%));
			margin-bottom: 10px;
		}
		.inner{
			padding: 30*$px;
			max-width: 1000px;
			margin: 0 auto;
		}
		
		ul.outline{
			>li{
				background: #fff;
				margin-bottom: 5px;
				position: relative;
				padding: 10*$px;
				padding-left: 90px;
				ul{
					list-style: disc;
					margin-left: 1.8em;
					font-size: 1.4rem;
				}
				i{
					position: absolute;
					left: 0;top: 0;
					//height: 100%;
					background: $base;
					width: 80px;
					height: 100%;
					@include flex-centering;
					font-size: 1.3rem;
					color: #fff;
				}
			}
		}//ul.outline
		
		.btn-entry{
			height: 60px;
			border-radius: 30px;
			max-width: 400px;
			display: block;
			background: #fdd33c;
			@include flex-centering;
			line-height: 1;
			font-size: 1.6rem;
			color: #222;
			font-weight: bold;
			letter-spacing: .1em;
			margin: 30*$px auto;
			margin-bottom: 0;
		}
		
	}//.outline

	a.backToIndex{
		width: 300px;
		height: 50px;
		font-size: 1.6rem;
		background: $base;
		color: #fff;
		display: block;
		@include flex-centering;
		margin: 50px auto;
		letter-spacing: .2em;
	}
	
}


}// media



